<template>
  <v-container fluid>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="getCompanyPaymentsWithDictionaries"
        :loading="loading"
        :options.sync="options"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :server-items-length="getCompanyPaymentsTotalCount || options.itemsPerPage * totalPages"
        :footer-props="{ 'items-per-page-options': [5, 10, 15, 20], 'show-current-page': true, 'show-first-last-page': true }"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar color="indigo lighten-5" flat>
            <BackButton />
            <v-toolbar-title>Платежі</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchText"
              class="shrink mr-10"
              append-icon="mdi-magnify"
              label="Пошук"
              single-line
              hide-details
            ></v-text-field>
          </v-toolbar>
        </template>

        <template v-slot:header.type="{ header }">
          <TableTitleFilter
            :values="selectedTypes"
            :options="paymentTypesDictionary"
            :title-text="header.text"
            item-text="name"
            @select="(selected) => (selectedTypes = selected)"
            @ok="applyFilter('type', selectedTypes)"
          />
        </template>
        <template v-slot:header.payment_status="{ header }">
          <TableTitleFilter
            :values="selectedStatuses"
            :options="paymentStatusesDictionary"
            :title-text="header.text"
            item-text="name"
            @select="(selected) => (selectedStatuses = selected)"
            @ok="applyFilter('payment_status', selectedStatuses)"
          />
        </template>
        <template v-slot:header.user="{ header }">
          <TableTitleFilter
            :values="selectedClients"
            :options="clients.map((item) => ({ ...item, id: item.user_id }))"
            :options-meta="clientsMeta"
            :options-params="clientsOptionsData"
            :title-text="header.text"
            item-value="user_id"
            @select="(selected) => (selectedClients = selected)"
            @ok="applyFilter('user.id', selectedClients)"
          >
            <template v-slot:item="{ item }">{{ item.first_name }} {{ item.last_name }}</template>
            <template v-slot:selection="{ item }">{{ item.first_name }} {{ item.last_name }}</template>
          </TableTitleFilter>
        </template>
        <template v-slot:header.created_at="{ header }">
          <TableTitleDateFilter
            :title-text="header.text"
            @select="(selected) => (selectedDates = selected)"
            @ok="applyDateFilter('created_at', selectedDates)"
          />
        </template>
        <template v-slot:item.id="{ item }">
          <router-link
            :to="{ name: 'PaymentCard', params: { id: currentCompanyId, paymentId: item.id } }"
            class="text-decoration-none"
          >
            {{ item.id }}
          </router-link>
        </template>
        <template v-slot:item.type="{ item }">
          {{ item.payment_type_name }}
        </template>
        <template v-slot:item.user="{ item }">
          <router-link
            v-if="item.user"
            :to="{ name: 'UserCard', params: { id: currentCompanyId, userId: item.user.id } }"
            class="text-decoration-none"
          >
            {{ item.user.first_name }} {{ item.user.last_name }}
          </router-link>
          <span v-else>N/A</span>
        </template>
        <template v-slot:item.device="{ item }">
          <router-link
            :to="
              item.device
                ? { name: 'DeviceCard', params: { id: currentCompanyId, deviceId: item.device.id } }
                : item.terminal
                ? { name: 'TerminalCard', params: { id: currentCompanyId, terminalId: item.terminal.id } }
                : item.service_point
                ? { name: 'ServicePointCard', params: { id: currentCompanyId, servicePointId: item.service_point.id } }
                : item.company
                ? { name: 'CompanyCard', params: { id: currentCompanyId } }
                : null
            "
            :class="[
              {
                disabled:
                  (item.device && item.device.status === 3) ||
                  (item.terminal && item.terminal.status === 3) ||
                  (item.service_point && item.service_point.status === 3) ||
                  (item.company && item.company.status === 3),
              },
              'text-decoration-none',
            ]"
          >
            <span v-if="item.device" :class="{ deleted: item.device.status === 3 }">
              Автомат {{ item.device.name }}{{ item.device.id }}
            </span>
            <span v-else-if="item.terminal" :class="{ deleted: item.terminal.status === 3 }">
              Термінал T{{ item.terminal.id }}
            </span>
            <span v-else-if="item.service_point" :class="{ deleted: item.service_point.status === 3 }">
              Точка {{ item.service_point.id }}, {{ item.service_point.name }}
            </span>
            <span v-else-if="item.company" :class="{ deleted: item.company.status === 3 }">
              Компанія {{ item.company.id }}, {{ item.company.name }}
            </span>
            <span v-else>N/A</span>
          </router-link>
        </template>
        <template v-slot:item.payment_status="{ item }">
          {{ item.payment_status_name }}
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | getShortTime }}
          <sup class="">{{ item.created_at | getShortDate }}</sup>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip v-if="item.user && canForwardToBonus(item.type, item.payment_status)" bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <button class="v-icon v-icon--link mdi mr-1" @click="forwardToBonus(item)">
                <img src="@/assets/images/cashback.svg" alt="Cashback icon" v-bind="attrs" v-on="on" width="16" height="16" />
              </button>
            </template>
            <span>Повернути на бонусний рахунок</span>
          </v-tooltip>
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small @click="navigateToPaymentCard(item)" v-bind="attrs" v-on="on"> mdi-eye-outline </v-icon>
            </template>
            <span>Переглянути</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="resetData"> Оновити </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <ConfirmActionModal @closeModal="showModal = false" :showModal="showModal">
      <template v-slot:title>Підтвердження</template>
      <template v-slot:text>Повернути цей платіж на бонусний рахунок?</template>
      <template v-slot:buttons>
        <v-btn @click="forwardToBonusConfirm" color="primary" text>Так</v-btn>
        <v-btn @click="showModal = false" text>Нi</v-btn>
      </template>
    </ConfirmActionModal>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { ROUTER_LINKS } from '@/const/router-links.enum'
import ConvertDate from '@/mixins/convertDate'
import resetTableData from '@/mixins/resetTableData'
import ConvertDevicesTypes from '@/mixins/convertDevicesTypes'
import BackButton from '@/components/common/BackButton'
import ConfirmActionModal from '@/components/dialogs/ConfirmActionModal'
import sortUtils from '@/mixins/sortUtils'
import TableTitleFilter from '@/components/common/filters/TableTitleFilter/TableTitleFilter'
import filterUtils from '@/mixins/filterUtils'
import searchUtils from '@/mixins/searchUtils'
import TableTitleDateFilter from '@/components/common/filters/TableTitleFilter/TableTitleDateFilter.vue'

export default {
  name: 'PaymentsList',
  components: { TableTitleDateFilter, TableTitleFilter, ConfirmActionModal, BackButton },

  mixins: [ConvertDevicesTypes, ConvertDate, resetTableData, sortUtils, filterUtils, searchUtils],

  data: () => ({
    ROUTER_LINKS: ROUTER_LINKS,
    loading: false,
    headers: [
      { text: 'ID', align: 'start', sortable: true, value: 'id' },
      { text: 'Тип', value: 'type', sortable: true },
      { text: 'Сума', value: 'amount', sortable: true },
      { text: 'Статус', value: 'payment_status', sortable: true },
      { text: 'Ідентифікатор', value: 'liq_pay_id', sortable: false },
      { text: 'Відправник', value: 'user', sortable: false },
      { text: 'Отримувач', value: 'device', sortable: false },
      { text: 'Створено', value: 'created_at', sortable: true },
      // { text: 'Змінено', value: 'updated_at', sortable: false },
      { text: '', value: 'actions', sortable: false, width: '125px', align: 'center' },
    ],
    sortBy: 'id',
    sortDesc: true,
    options: {},
    showModal: false,
    clickedPaymentId: null,
    totalPages: 2,
    payload: null,
    selectedTypes: [],
    selectedStatuses: [],
    searchFields: [
      'id',
      'liq_pay_id',
      'user.first_name',
      'user.last_name',
      'device.id',
      'device.name',
      'terminal.id',
      'terminal.name',
      'service_point.id',
      'service_point.name',
    ],
  }),

  computed: {
    ...mapState('dictionaries', ['paymentTypesDictionary', 'paymentStatusesDictionary', 'machinesTypesDictionary']),
    ...mapGetters('payments', ['getCompanyPaymentsTotalCount', 'getCompanyPaymentsWithDictionaries']),

    currentCompanyId() {
      return this.$route.params.id
    },
  },

  watch: {
    options: {
      handler(val) {
        if (!this.getCompanyPaymentsTotalCount && val.page === this.totalPages) {
          this.totalPages++
        }
        this.paginateTo()
      },
      deep: true,
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    ...mapActions('payments', ['loadCompanyPayments', 'refundPayment']),
    ...mapActions('logs', ['displayWarningAlert']),

    initialize() {
      this.payload = {
        company: this.currentCompanyId,
      }
    },

    async paginateTo() {
      if (!this.currentCompanyId) {
        await this.displayWarningAlert({ message: 'Оберіть компанію' })
        await this.$router.replace('/spa')
        return
      }
      this.loading = true
      this.payload = {
        ...this.payload,
        page: this.options.page,
        limit: this.options.itemsPerPage,
        sort: this.sortObject,
        search: this.searchObject,
        ...this.filterObject,
      }
      try {
        await this.loadCompanyPayments(this.payload)
      } finally {
        this.loading = false
      }
    },

    forwardToBonus(item) {
      this.showModal = true
      this.clickedPaymentId = item.id
    },

    async forwardToBonusConfirm() {
      this.showModal = false
      const params = {
        companyId: this.currentCompanyId,
        paymentId: this.clickedPaymentId,
      }
      await this.refundPayment(params)
      await this.paginateTo()
    },

    navigateToPaymentCard(item) {
      const clickedItemId = item.id
      this.$router.push({ name: 'PaymentCard', params: { id: this.currentCompanyId.toString(), paymentId: clickedItemId } })
    },

    convertManufactureDate() {
      // const date = new Date(this.editedItem.manufacture_date)
      // this.convertedManufactureDate = date.toISOString().split('T')[0]
      this.convertedManufactureDate = this.editedItem.manufacture_date.split('T')[0]
    },

    canForwardToBonus(type, status) {
      return type !== 0 && type !== 4 && status === 3
    },
  },
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vars';
.deleted {
  color: $red;
}
.disabled {
  pointer-events: none;
  opacity: 0.4;
}
</style>
