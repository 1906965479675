import { toCamelCase } from '@/helpers/toCamelCase'
import { mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      filterObject: null,
      selectedUsers: [],
      usersOptionsData: null,
      selectedInitiators: [],
      initiatorsOptionsData: null,
      selectedClients: [],
      clientsOptionsData: null,
      selectedCompanies: [],
      companiesOptionsData: null,
      selectedBalanceHolders: [],
      balanceHoldersOptionsData: null,
      selectedServicePoints: [],
      servicePointsOptionsData: null,
      selectedTerminals: [],
      terminalsOptionsData: null,
      selectedDevices: [],
      devicesOptionsData: null,
      selectedDates: [],
    }
  },

  created() {
    this.usersOptionsData = {
      loadingFunction: this.loadUsers,
      payload: {
        companyId: this.currentCompanyId,
        forSearch: 1,
      },
    }
    this.initiatorsOptionsData = {
      loadingFunction: this.loadInitiators,
      payload: {
        companyId: this.currentCompanyId,
        forSearch: 1,
      },
    }
    this.clientsOptionsData = {
      loadingFunction: this.loadClients,
      payload: {
        company: this.currentCompanyId,
        forSearch: 1,
      },
    }
    this.companiesOptionsData = {
      loadingFunction: this.getCompanies,
      payload: {
        forSearch: 0,
      },
    }
    this.balanceHoldersOptionsData = {
      loadingFunction: this.loadBalanceHolders,
      payload: {
        companyId: this.currentCompanyId,
        forSearch: 1,
      },
    }
    this.servicePointsOptionsData = {
      loadingFunction: this.loadServicePoints,
      payload: {
        company: this.currentCompanyId,
        forSearch: 1,
      },
    }
    this.terminalsOptionsData = {
      loadingFunction: this.loadTerminals,
      payload: {
        companyId: this.currentCompanyId,
        show_unconnected: 0,
        forSearch: 1,
      },
    }
    this.devicesOptionsData = {
      loadingFunction: this.loadDevices,
      payload: {
        company: this.currentCompanyId,
        show_unconnected: 0,
        forSearch: 1,
      },
    }
  },

  computed: {
    ...mapState('users', ['users', 'usersMeta', 'initiators', 'initiatorsMeta', 'clients', 'clientsMeta']),
    ...mapState('partners', ['balanceHolders', 'balanceHoldersMeta']),
    ...mapState('servicePoints', ['servicePoints', 'servicePointsMeta']),
    ...mapState('terminals', ['terminals', 'terminalsMeta']),
    ...mapState('devices', ['devices', 'devicesMeta']),
  },

  methods: {
    ...mapActions('users', ['loadUsers', 'loadInitiators', 'loadClients']),
    ...mapActions('partners', ['loadBalanceHolders']),
    ...mapActions('servicePoints', ['loadServicePoints']),
    ...mapActions('terminals', ['loadTerminals']),
    ...mapActions('devices', ['loadDevices']),

    getCSV(arr) {
      return arr.map((item) => ('value' in item ? item.value : item.id)).join(',')
    },

    async applyFilter(fieldName, selectedArr) {
      const field = toCamelCase(fieldName)
      if (selectedArr.length) {
        this.filterObject = {
          ...this.filterObject,
          [field]: this.getCSV(selectedArr),
        }
      } else {
        this.filterObject[field] = null
      }
      this.options.page = 1
      await this.paginateTo()
    },

    async applyDateFilter(fieldName, selectedArr) {
      const field = toCamelCase(fieldName)
      if (selectedArr.length === 2) {
        this.filterObject = {
          ...this.filterObject,
          [field]: {
            fromDate: selectedArr[0],
            toDate: selectedArr[1],
          },
        }
      } else {
        this.filterObject[field] = null
      }
      this.options && (this.options.page = 1)
      await this.paginateTo()
    },
  },
}
