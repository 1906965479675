<template>
  <div class="d-inline-flex align-center">
    <div>{{ titleText }}</div>
    <div class="ml-1">
      <v-menu v-model="isMenuOpened" :close-on-content-click="false" :min-width="MENU_MIN_WIDTH" :max-width="MENU_MAX_WIDTH">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <v-icon :color="isFilterApplied ? 'warning' : ''"> mdi-menu-down </v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-col cols="12" sm="10">
                <DateFilter :dates="dates" @select="select" @clear="handleClear"></DateFilter>
              </v-col>
              <v-col cols="12" sm="2">
                <OkClear
                  :disable-ok="isFilterApplied || !dates.length"
                  :disable-clear="!isFilterApplied"
                  @ok="handleOk"
                  @clear="handleClear"
                />
              </v-col>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script>
import DateFilter from '@/components/common/filters/DateFilter'
import OkClear from '@/components/common/filters/TableTitleFilter/OkClear.vue'

export default {
  name: 'TableTitleDateFilter',

  components: { OkClear, DateFilter },

  emits: ['ok', 'select'],

  props: {
    values: {
      type: Array,
      default: () => [],
    },
    titleText: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    MENU_MIN_WIDTH: 450,
    MENU_MAX_WIDTH: 450,
    isMenuOpened: false,
    isFilterApplied: false,
    disableOk: false,
    disableClear: false,
    dates: [],
  }),

  watch: {
    values(val) {
      this.dates = [...val]
    },
  },

  methods: {
    select(selected) {
      this.dates = selected
      this.isFilterApplied = false
      this.$emit('select', selected)
    },

    handleOk() {
      this.isMenuOpened = false
      this.$emit('ok', this.dates)
      this.isFilterApplied = true
    },

    handleClear() {
      this.select([])
      this.isMenuOpened = false
      this.$emit('ok', this.dates)
      this.isFilterApplied = false
    },
  },
}
</script>
