import { toCamelCase } from '@/helpers/toCamelCase'

export default {
  data() {
    return {
      searchText: '',
      searchObject: null,
      inputTimeout: null,
    }
  },

  watch: {
    searchText(val) {
      if (!val) {
        this.searchObject = null
        this.options.page === 1 ? this.paginateTo() : (this.options.page = 1)
        clearTimeout(this.inputTimeout)
      } else {
        if (this.inputTimeout) {
          clearTimeout(this.inputTimeout)
          this.inputTimeout = null
        }
        this.inputTimeout = setTimeout(() => {
          for (const item of this.searchFields) {
            if (!this.searchObject) {
              this.searchObject = {}
            }
            this.searchObject[toCamelCase(item)] = val
          }
          this.options.page === 1 ? this.paginateTo() : (this.options.page = 1)
        }, 1500)
      }
    },
  },
}
